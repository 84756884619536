:global {
  // .cal-option {
  //   width: 300px;
  //   text-align: center;
  //   cursor: pointer;
  //   transition: 0.3s all ease-in-out;

  //   .anticon {
  //     opacity: 0;
  //     position: absolute;
  //     top: 8px;
  //     right: 8px;
  //     color: #4096ff;
  //     transition: 0.3s all ease-in-out;
  //   }

  //   &.selected {
  //     border-color: #91caff;
  //     background-color: #e6f4ff;
  //     .anticon {
  //       opacity: 1;
  //     }
  //   }
  // }

  .payment-logo {
    justify-content: space-between;
    img {
      height: 2vw;
      width: auto;
      margin-bottom: 1vw;
    }
  }

  .ant-steps.ant-steps-label-vertical {
    .ant-steps-item-content {
      margin-top: 0.5rem;
    }
    .ant-steps-item-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      white-space: nowrap;
    }
  }

  .ant-steps-item {
    .ant-avatar {
      color: var(--bs-button-color);
      font-size: 1rem;
    }
  }
  .ant-steps-item-active {
    .ant-avatar {
      background: var(--bs-primary);
    }
    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      &::after {
        background-color: var(--bs-primary) !important;
      }
    }
  }
  .ant-steps-item-wait {
    &.ant-steps-item-disabled {
      .ant-avatar {
        opacity: 0.5;
      }
    }
  }
  .ant-steps-item-finish {
    .ant-avatar {
      background: var(--bs-primary);
      opacity: 0.5;
    }
  }
  @media screen and (min-width: 768px), screen and (min-width: 1024px) {
    .part-calculation-steps-wrapper {
      .ant-steps-item-active.ant-steps-item::after {
        background: var(--bs-primary) !important;
      }
    }
  }
  .part-calculation-steps-wrapper {
    overflow: hidden;
    border: 1px solid #00000066;
    background: white;
    .part-calculation-steps {
      padding-top: 0;
      .ant-steps-item-title {
        font-size: 1rem;
        z-index: 3;
      }
    }
    .ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) {
      .ant-steps-item {
        padding: 0.5rem 0;
      }
    }
    .ant-steps.ant-steps-navigation {
      .ant-steps-item-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
      }
    }
    .ant-steps.ant-steps-navigation .ant-steps-item::after {
      top: 50%;
      width: calc(30px + 0.5rem);
      height: calc(30px + 0.5rem);
      border-top: 1px solid #00000066;
      border-inline-end: 1px solid #00000066;
    }

    .ant-steps.ant-steps-navigation .ant-steps-item::before {
      min-height: 100%;
      transition: none;
    }
    .ant-steps-item::after {
      background: white !important;
      z-index: 2;
    }

    .ant-steps-item-active {
      .ant-avatar-icon {
        background-color: var(--step-avatar-background);
        border: 1px solid var(--step-avatar-border);
        color: var(--step-avatar-text-color);
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: var(--step-item-title-text-color) !important;
        }
      }
    }
  }
}
