:global {
  .project-table {
    .part-cell-name {
      max-width: 300px;
      white-space: pre-line;
      text-overflow: ellipsis;
    }
    .part-list {
      overflow: hidden;
      max-width: 81vw;
      .ant-card-body {
        padding: 12px;
      }
      .part-name {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
    .ant-image {
      // width: 2.2em !important;
      height: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #f1f1f1;
      border-radius: 5px;
      overflow: hidden;
      margin: 0;
      .ant-image-img {
        width: auto;
      }
    }
    .ant-image, .part-svg {
      background-color: #f1f1f1;
    }
    .part-group {
      .ant-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .ant-descriptions {
    .ant-descriptions-header {
      margin-bottom: 12px;
    }
  }
  .project-summary {
    background-color: #FEF9F0;
    hr {
      margin: 0.5rem 0;
    }
  }

  .projects-title-description {
    font-size: 0.9rem;
    color: #00000066;
    font-weight: 500;
  }
}
