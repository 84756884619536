:global {
  .calculation-tool-box {
    // height: 44px;
    .delivery-date-inline {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .ant-form-item-control-input,
      form,
      .ant-form-item {
        height: 100%;
      }
    }
  }

  .sticky-outer-wrapper .calculation-tool-box {
    padding: 0;
    transition: padding 0.2s ease-in-out;
  }

  .sticky-outer-wrapper.active {
    .calculation-tool-box {
      padding: 10px;
      background: white;
      box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.4);
    }
  }
}
